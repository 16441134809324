<script>
export default {
  name: "FormationsGalaxy",
  props: {
    formations: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    toggleSelect(id) {
      if(this.selected == id) {
        this.selected = null
      } else {
        this.selected = id
      }
    }
  }
}
</script>

<style>

</style>
