<script>
export default {
  name: "Slider",
  props: ['objects'],
  data() {
    return {
      selected: 1
    }
  }
}
</script>

<style>

</style>
