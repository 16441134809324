<template>
  <li class="flex flex-col w-full md:w-1/2 lg:w-1/3 p-3">
    <a :href="formation.attributes.url" class="flex flex-col flex-1 inline-block border rounded hover:shadow-lg">
      <div class="relative h-64 flex-shrink-0">
        <div class="absolute left-0 top-0 ml-4 mt-4" v-if="formation.attributes.cpf">
          <span class="uppercase text-gray-100 px-4 py-2 bg-yellow-500 rounded-full text-xs font-extrabold">Éligible CPF</span>
        </div>
        <img class="w-full h-full object-cover" :src="formation.attributes.image_couverture.url" alt="" loading="lazy">
      </div>
      <div class="p-8 flex flex-col flex-1">
        <span class="text-xs text-purple font-extrabold">{{formation.attributes.categorie}}</span>
        <span class="text-xl font-extrabold mt-2">{{formation.attributes.nom}}</span>
        <p class="mt-4 text-gray-400 flex-1">
          {{formation.attributes.resume}}
        </p>
        <span class="mt-2 text-xs text-purple font-extrabold">
          {{formation.attributes.duree_formation}}
        </span>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: "FormationCard",
  props: {
    formation: {
      type: Object,
      default: function() {
        return {
          id: null,
          attributes: {
            url: '',
            cpf: false,
            nom: '',
            categorie: '',
            resume: '',
            image_couverture: {},
            duree_formation: ''
          }
        }
      }
    }
  }
}
</script>

<style>

</style>
