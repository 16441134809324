<template>
  <div>
    <div class="flex flex-col md:flex-row md:items-center md:justify-between">
      <ul class="flex flex-wrap -mx-1">
        <li class="px-1 my-1" v-for="object in filters" :key="object.id" :class="classes(object)">
          <a class="inline-block uppercase text-gray-500 text-xs font-extrabold rounded-full hover:bg-gray-100 hover:text-pink" :href="`/catalog/${object.id}`" @click.prevent="click(object)">
            <span class="block py-2 px-4">{{object.text}}</span>
          </a>
        </li>
      </ul>

      <div class="flex-shrink-0 py-4 px-4" v-if="enableCpfToggle">
        <label class="toggle flex items-center" for="cpf-filter">
          <input class="toggle__input" name="cpf-filter" type="checkbox" id="cpf-filter" v-model="filterCpf">
          <div class="toggle__fill"></div>
          <span class="ml-2 text-sm text-gray-600 leading-none mt-1 font-semibold">Éligible au CPF</span>
        </label>
      </div>
    </div>
    <slot name="list" v-bind:filteredRecords="filteredRecords"></slot>
    <slot name="extra-content" v-bind:filter="active"></slot>
  </div>
</template>
<script>
export default {
  name: "List",
  props: {
    filters: {
      type: Array,
      default: function() {
        return []
      }
    },
    records: {
      type: Array,
      default: function() {
        return []
      }
    },
    selectedFilter: {},
    enableCpfToggle: {
      type: Boolean,
      default: true
    },
    enableUrlChange: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      active: null,
      filterCpf: false
    }
  },
  created() {
    if(this.selectedFilter) {
      this.active = this.selectedFilter
    } else {
      if(this.filters.length > 0) {
        this.active = this.filters[0].id
      }
    }

    if(this.enableUrlChange) {
      history.replaceState(this.historyState, '', this.historyUrl);
      window.onpopstate = (event) => this.onStatePop(event)
    }
  },
  methods: {
    onStatePop(event) {
      if(event.state && event.state.category) {
        this.active = event.state.category
      }
    },
    click(category) {
      this.active = category.id
      if(this.enableUrlChange) { history.pushState(this.historyState, '', this.historyUrl) }
    },
    classes(category) {
      return {
        'active': this.active == category.id
      }
    }
  },
  computed: {
    historyUrl() {
      if(this.active === "tous") {
        return "/catalogue"
      }

      return `/catalogue/${this.active}`
    },
    historyState() {
      return { category: this.active }
    },
    filteredRecords() {
      let parsed = this.records.sort((a,b) => a.position - b.position )

      if(this.filterCpf) {
        parsed = parsed.filter(x => x.attributes.cpf === true)
      }

      if(this.active && this.active != this.filters[0].id) {
        parsed = parsed.filter(x => x.attributes.category_id.indexOf(this.active) !== -1 )
      }

      return parsed
    }
  }
}
</script>

<style lang="scss" scoped>
  li.active {
    a{
      @apply bg-pink text-white;
    }
  }
</style>
