<script>
export default {
  name: "NewsletterForm",
  data() {
    return {
      email: null,
      success: null,
      error: null
    }
  },
  methods: {
    submit() {
      this.success = null
      this.error = null

      const csrfToken = document.querySelector("[name='csrf-token']").content
      const body = JSON.stringify({
        newsletter_subscription: { email: this.email }
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: body
      };

      fetch(this.$refs.form.action, requestOptions)
        .then( response => {
          if (!response.ok) { throw response }
          return response.json()
        })
        .then(data => {
          this.success = data.message
        })
        .catch(err => {
          err.json().then((json) => {
            this.error = json.error
          })
        })
    }
  }
}
</script>
