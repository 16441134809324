import "../stylesheets/catalog"

import Vue from "vue/dist/vue.esm";
import LazyLoad from "vanilla-lazyload";
import List from './components/List.vue'
import FormationsGalaxy from './components/FormationsGalaxy.vue'
import NewsletterForm from './components/NewsletterForm.vue'
import Slider from './components/Slider.vue'
import FormationCard from "./components/FormationCard.vue"
import Rails from '@rails/ujs';

Rails.start();
Vue.component("list", List)
Vue.component("formations-galaxy", FormationsGalaxy)
Vue.component("newsletter-form", NewsletterForm)
Vue.component("slider", Slider)
Vue.component("formation-card", FormationCard)

document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    el: "#app",
    data: {
      menuOpen: false
    },
    watch: {
      menuOpen: function() {
        const menu = document.getElementById("menu-links")

        if(this.menuOpen) {
          menu.classList.remove('hidden')
        } else {
          menu.classList.add('hidden')
        }
      }
    },
    methods: {
      toggle() {
        this.menuOpen = !this.menuOpen
      }
    }
  });

  const lazyLoadOptions = {
    elements_selector: ".lazy",
    to_webp: true,
  };
  new LazyLoad(lazyLoadOptions);
});
